@import "src/assets/scss/font-awesome/font-awesome";

.react-add-to-calendar {
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  position: relative;
  display: inline-block;
  margin: 0 auto;

  &__wrapper {
    zoom: 1;
    cursor: pointer;
  }

  &__button {
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #aab9d4;
    border-radius: 3px;
    color: #000;

    &--light {
      background-color: #fff;
    }
  }

  &__icon {
    &--right {
      padding-left: 5px;
    }

    &--left {
      padding-right: 5px;
    }
  }

  &__dropdown {
    position: absolute;
    top: 30px;
    left: 1px;
    width: 93%;
    padding: 5px 0 5px 8px;
    box-shadow: 1px 3px 6px rgba(0, 0, 0, .15);
    border: 1px solid #a8a8a8;
    background-color: #fff;
    text-align: left;

    ul {
      list-style: none;
      margin: 0;

      li {

        a {
          color: #000;
          text-decoration: none;

          i {
            padding-right: 10px;
          }
        }
      }
    }
  }
}
