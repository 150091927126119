// Defaults are imported at the bottom of this file.
.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0;
}

button.rbc-btn {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled].rbc-btn {
  cursor: not-allowed;
}

button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}


$out-of-range-color: lighten(#333, 40%) !default;
$out-of-range-bg-color: lighten(#333, 70%) !default;

$calendar-border: #cddbe9 !default;
$cell-border: #cddbe9 !default;

$border-color: #c82020 !default;

$segment-width: percentage(1 / 7) !default;

$time-selection-color: white !default;
$time-selection-bg-color: rgba(0,0,0, .50) !default;
$date-selection-bg-color: rgba(0,0,0, .10) !default;


$event-bg: #0f70c6 !default;
$event-border: darken(#2f5779, 10%) !default;
$event-outline: #3b99fc !default;
$event-color: #fff !default;
$event-border-radius: 5px !default;
$event-padding: 2px 5px !default;
$event-zindex: 4 !default;

$btn-color: #373a3c !default;
$btn-bg: #f4f4ff !default;
$btn-border: #cccccc !default;

$current-time-color: #74ad31 !default;

$rbc-css-prefix: rbc-i !default;

$today-highlight-bg: #eaf6ff !default;


// ?? scss is bottom to up compilation
@import '~react-big-calendar/lib/sass/styles';
@import '~react-big-calendar/lib/css/react-big-calendar.css';
